import Image from "next/image";
import styles from './BlogCategories.module.css';
import {IHomeBlogPost} from '../../interfaces';
import { LeadPopUpButton } from '../shared/lead/LeadPopUpButton';
import { ElemenetType } from '../../lib/constants';
import { NavLink } from '../ui';
import { CountryCode } from '../../lib/constants';
import services from '../../data/homeDIServices.json'
import imageLoader from "../../lib/helpers/imageLoader";


export function BlogCategories(props:{countryCode:CountryCode}){
    if(props.countryCode != 'in')return null;
    
    const siteURL=process.env.SITE_URL+"/"+props.countryCode;
    
    return(
        <section className="section endToendSolution">
            <div className="container">
                <h2 className={`${styles.heading} textCenter font26 text222 f500 mb12 fontSM20`}>Interior Design Services Under One Roof</h2>
                <p className={`${styles.subHeading} textCenter mb46 font16 fontSM14`}>From ideation to execution, we offer functional design solutions for your home.</p>
                <div className={`${styles.solutionList} mb30`}>
                    {
                      services.map((data,idx)=>BlogCategory(siteURL,data,idx))
                    }
                </div>
                <LeadPopUpButton ElemenetType={ElemenetType.button}  ButtonClass={`${styles.fullWidthBtn} btn btnPrimery btnCenter`} />
            </div>
        </section>
    )
}

function BlogCategory(siteURL:string, data:IHomeBlogPost,idx:number){
    return (
            <NavLink href={siteURL+'/interior-design-ideas/'+data.cat_url} key={idx}>
          <div className={styles.solutionItem}>
                <figure className={styles.figImg} >
                    <Image
                        width={50}
                        height={50}
                        loader={imageLoader}
                        src={`homeDIService/${data.serviceImage}`}
                        alt={data.serviceImage}
                        className="imgFluid"
                         />
                </figure>
                <p className={`font12 ${styles.catName}`} >{data.serviceType}</p>
        </div>
            </NavLink>
    );
}